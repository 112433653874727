<template>
    <el-dialog :title="title" :visible.sync="editor.visible" @open="open" :close-on-click-modal="false"
                destroy-on-close center append-to-body width="1000px">
        <el-form ref="form" :model="form" :rules="rules" class="x-edit">
            <x-divider title="基本信息"></x-divider>
            <el-form-item label="订单号" prop="dingDanHao" >
                <el-input v-model="form.dingDanHao" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="会员号" prop="huiYuanHao" >
                <el-input v-model="form.huiYuanHao" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="会员Id" prop="huiYuanId" >
                <el-input v-model="form.huiYuanId" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="会员名称" prop="huiYuanMC" >
                <el-input v-model="form.huiYuanMC" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="会员手机号" prop="huiYuanShouJiHao" >
                <el-input v-model="form.huiYuanShouJiHao" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="支付金额" prop="zhiFuJinE" >
                <el-input v-model="form.zhiFuJinE" size="small" maxlength="15" show-word-limit/>
            </el-form-item>
            <el-form-item label="使用积分" prop="shiYongJiFen" >
                <el-input v-model="form.shiYongJiFen" size="small" maxlength="" show-word-limit/>
            </el-form-item>
            <el-form-item label="支付类型" prop="zhiFuLX" >
                <el-input v-model="form.zhiFuLX" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="消费时间" prop="xiaoFeiSJ">
                <el-date-picker v-model="form.xiaoFeiSJ" size="small" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"/>
            </el-form-item>
            <el-form-item label="收银员" prop="shouYinYuan" >
                <el-input v-model="form.shouYinYuan" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="收银员名称" prop="shouYinYuanMC" >
                <el-input v-model="form.shouYinYuanMC" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="支付流水号" prop="zhiFuLiuShuiHao" >
                <el-input v-model="form.zhiFuLiuShuiHao" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="会员优惠" prop="huiYuanYouHui" >
                <el-input v-model="form.huiYuanYouHui" size="small" maxlength="15" show-word-limit/>
            </el-form-item>
            <el-form-item label="抹零" prop="moLing" >
                <el-input v-model="form.moLing" size="small" maxlength="15" show-word-limit/>
            </el-form-item>
            <el-form-item label="总优惠" prop="zongYouHui" >
                <el-input v-model="form.zongYouHui" size="small" maxlength="15" show-word-limit/>
            </el-form-item>
            <el-form-item label="订单类型" prop="dingDanLX" >
                <el-input v-model="form.dingDanLX" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="订单备注" prop="dingDanBeiZhu" class="line">
                <el-input v-model="form.dingDanBeiZhu" maxlength="" show-word-limit type="textarea"/>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="save" icon="el-icon-document-add">保 存</el-button>
            <el-button @click="cancel" icon="el-icon-switch-button">取 消</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import {add, update, getUpdate} from "@/service/jygl/ShouKuanMX";
    import XEditBase from "@/components/x/XEditBase";
    import {ruleBuilder} from '@/util/validate';

    export default {
        mixins: [XEditBase],
        beforeCreate() {
            let {required} = ruleBuilder;
            this.rules = {
                dingDanHao: [], // 订单号
                huiYuanHao: [], // 会员号
                huiYuanId: [], // 会员Id
                huiYuanMC: [], // 会员名称
                huiYuanShouJiHao: [], // 会员手机号
                zhiFuJinE: [], // 支付金额
                shiYongJiFen: [], // 使用积分
                zhiFuLX: [], // 支付类型
                xiaoFeiSJ: [], // 消费时间
                shouYinYuan: [], // 收银员
                shouYinYuanMC: [], // 收银员名称
                zhiFuLiuShuiHao: [], // 支付流水号
                huiYuanYouHui: [], // 会员优惠
                moLing: [], // 抹零
                zongYouHui: [], // 总优惠
                dingDanLX: [], // 订单类型
                dingDanBeiZhu: [], // 订单备注
            }
            this.titlePrefix = '收款明细';
            this.defaultForm = {
                id: null,
                dingDanHao: "", // 订单号
                huiYuanHao: "", // 会员号
                huiYuanId: "", // 会员Id
                huiYuanMC: "", // 会员名称
                huiYuanShouJiHao: "", // 会员手机号
                zhiFuJinE: "", // 支付金额
                shiYongJiFen: "", // 使用积分
                zhiFuLX: "", // 支付类型
                xiaoFeiSJ: "", // 消费时间
                shouYinYuan: "", // 收银员
                shouYinYuanMC: "", // 收银员名称
                zhiFuLiuShuiHao: "", // 支付流水号
                huiYuanYouHui: "", // 会员优惠
                moLing: "", // 抹零
                zongYouHui: "", // 总优惠
                dingDanLX: "", // 订单类型
                dingDanBeiZhu: "", // 订单备注
                shangPinXiaoShouMXS:[]
            };
            this.addService = add;
            this.updateService = update;
            this.getUpdateService = getUpdate;
        }
    }
</script>

<style scoped>

</style>
