<template>
    <el-dialog :title="title" :visible.sync="jinEDetailVisible"  :close-on-click-modal="false"
               destroy-on-close center append-to-body width="700px">
        <el-table ref="table" v-loading="loading" :data="tableData" border>
            <el-table-column  label="支付类型">
                <x-dict-show slot-scope="{row}" :code="row.zhiFuLX" dictType="T_PAY"/>
            </el-table-column>
            <el-table-column  prop="zhiFuJinE" label="金额(元)"/>
            <el-table-column  prop="zhiFuLiuShuiHao" label="内部流水号"/>
        </el-table>
    </el-dialog>
</template>

<script>

    export default {
        name: "ZhiFuJinEDetail",
        mixins: [],
        data(){
            return {
                title:"支付详情",
                tableData:[],
                jinEDetailVisible:false,
                tableData:[],
                loading: false,
                height: 100,
            }
        },
    }
</script>

<style scoped>

</style>
