<template>
    <div class="x-page-container" ref="resize">
        <div class="page-tips">
            <p>默认显示当前月数据</p>
        </div>
        <!-- 查询区 -->
        <div class="x-page-search">
            <x-search-item label="交易时间" textWidth="220px">
                <el-date-picker placeholder="起始时间" v-model="search.xiaoFeiSJBegin" size="small" type="datetime"/>
                <el-date-picker placeholder="结束时间" v-model="search.xiaoFeiSJEnd" size="small" type="datetime"
                                slot="end"/>
            </x-search-item>
            <x-search-item label="订单号">
                <el-input v-model="search.dingDanHao" size="small"/>
            </x-search-item>
            <x-search-item label="会员号">
                <el-input v-model="search.huiYuanHao" size="small"/>
            </x-search-item>
            <x-search-item label="支付类型">
                <x-selector-one v-model="search.zhiFuLX" size="small" dictType="T_PAY"/>
            </x-search-item>
            <x-search-item label="订单类型">
                <x-selector-one v-model="search.dingDanLX" size="small" dictType="T_ORDERS"/>
            </x-search-item>
            <x-search-item label="顾客类型">
                <x-selector-one v-model="search.guKeLX" size="small" dictType="T_CUSTOMER"/>
            </x-search-item>
            <div class="x-search-item">
                <el-button type="primary" size="mini" @click="handleSearch">搜索</el-button>
                <el-button type="primary" size="mini" @click="handleClear">清空</el-button>
                <excel-export :tableData="exportTableData" :headAndKey="headAndKey" :isSum="true" :condition="condition"
                              fileName="收款明细表">
                    <el-button type="success" size="mini" style="margin-left: 10px">导出</el-button>
                </excel-export>
            </div>
        </div>
        <!--<div class="x-page-btn">-->
        <!--    <el-button type="success" size="mini" round @click="handleAdd">新增</el-button>-->
        <!--</div>-->
        <div class="table-top-card-box">
            <x-card class="shou-kuan-card" label="销售笔数" :text="cardData.xsbs"></x-card>
            <x-card class="shou-kuan-card" label="销售总额" :text="cardData.xsze"></x-card>
            <x-card class="shou-kuan-card" label="退款笔数" :text="cardData.tkbs"></x-card>
            <x-card class="shou-kuan-card" label="退款总额" :text="cardData.tkze"></x-card>
            <x-card class="shou-kuan-card" label="实收总额" :text="cardData.ssze"></x-card>
            <x-card class="shou-kuan-card" label="会员折扣金额" :text="cardData.hyzkje"></x-card>
            <x-card class="shou-kuan-card" label="优惠券总额" :text="cardData.yhjze"></x-card>
            <x-card class="shou-kuan-card" label="抹零" :text="cardData.ml"></x-card>
        </div>
        <!-- 列表区域 -->
        <el-table ref="table" v-loading="table.loading" :data="table.data" :height="table.height" border
                  @selection-change="handleSelectionChange">
            <el-table-column width="50" label="序号" type="index" fixed/>
            <el-table-column width="180" prop="dingDanHao" label="订单号"/>
            <el-table-column width="180" prop="huiYuanMC" label="会员名称">
                <template slot-scope="scope">
                    <span v-if="scope.row.huiYuanMC">{{scope.row.huiYuanMC}}</span>
                    <span v-else>散客</span>
                </template>
            </el-table-column>
            <el-table-column width="180" prop="huiYuanShouJiHao" label="会员手机号"/>
            <el-table-column width="180" label="支付金额">
                <template slot-scope="scope">
                    <el-button v-if="tkddDict===scope.row.dingDanLX" type="text" @click="handlePay(scope.row)">-{{scope.row.zhiFuJinE}}</el-button>
                    <el-button v-else type="text" @click="handlePay(scope.row)">{{scope.row.zhiFuJinE}}</el-button>
                </template>
            </el-table-column>
            <el-table-column width="180" prop="shiYongJiFen" label="使用积分"/>
            <el-table-column width="180" label="支付类型">
                <x-dict-show slot-scope="{row}" :code="row.zhiFuLX" dictType="T_PAY"/>
            </el-table-column>
            <el-table-column width="180" prop="xiaoFeiSJ" label="消费时间"/>
            <el-table-column width="180" prop="shouYinYuan" label="收银员"/>
            <el-table-column width="180" prop="youHuiJuanDK" label="优惠券抵扣金额"/>
            <el-table-column width="180" prop="huiYuanYouHui" label="会员价及会员折扣优惠"/>
            <el-table-column width="180" prop="moLing" label="抹零"/>
            <el-table-column width="180" prop="zongYouHui" label="总优惠"/>
            <el-table-column width="180" label="订单类型">
                <x-dict-show slot-scope="{row}" :code="row.dingDanLX" dictType="T_ORDERS"/>
            </el-table-column>
            <el-table-column width="180" prop="dingDanBeiZhu" label="订单备注"/>
            <el-table-column width="250" align="center" label="操作" fixed="right">
                <template slot-scope="{ row, $index }">
                    <el-button type="success" size="mini" round @click="handleToDetail(row)">详情</el-button>
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination background
                       :total="table.total"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       layout="total, sizes, prev, pager, next, jumper"/>
        <!-- 编辑页区域 -->
        <edit ref="edit" :editor="editor" @submitted="refresh"/>
        <!--<detail ref="detail"/>-->
        <shou-kuan-m-x-detail ref="skmxDetail"/>
        <!-- 支付金额详情 -->
        <zhi-fu-jin-e-detail ref="zhiFuDetail"/>

    </div>
</template>
<script>
    import * as service from "@/service/jygl/ShouKuanMX";
    import XTableBase from "@/components/x/XTableBase";
    import Edit from "@/view/jygl/skmx/ShouKuanMXEdit";
    import XCard from "@/components/x/card/XCard";
    import {JYGL_SKMX_CARD_SUFFIX, KONG_ZHI_XIAN_SHI,DICT_TYPE_ORDERS} from '@/util/constant'
    import {deepCopy, deepMerge} from "@/util/objects";
    import {getDictType, initDictByType} from "@/util/dict";
    import ZhiFuJinEDetail from "@/view/jygl/skmx/ZhiFuJinEDetail";
    import ShouKuanMXDetail from "@/view/jygl/skmx/ShouKuanMXDetail";

    export default {
        name: "ShouKuanMXList",
        mixins: [XTableBase],
        components: {ShouKuanMXDetail, ZhiFuJinEDetail, Edit, XCard},
        beforeCreate() {
            this.defaultCardData = {
                xsbs: '0',
                xsze: '0',
                tkbs: '0',
                tkze: '0',
                ssze: '0',
                hyzkje: '0',
                sydpzkje: '0',
                yhjze: '0',
                ml: '0'
            };
        },
        data() {
            this.refreshService = service.list;
            this.service = service;
            return {
                search: {
                    dingDanHao: '',
                    xiaoFeiSJBegin: '',
                    xiaoFeiSJEnd: '',
                    huiYuanHao: '',
                    zhiFuLX: '',
                    dingDanLX: '',
                    guKeLX: '',
                },
                headAndKey: {
                    dingDanHao: "订单号",
                    huiYuanMC: "会员名称",
                    huiYuanShouJiHao: "会员手机号",
                    zhiFuJinE: "支付金额",
                    shiYongJiFen: "使用积分",
                    zhiFuLX: "支付类型",
                    xiaoFeiSJ: "消费时间",
                    shouYinYuan: "收银员",
                    youHuiJuanDK: "优惠券抵扣金额",
                    huiYuanYouHui: "会员价/会员折扣",
                    moLing: "抹零",
                    zongYouHui: "总优惠",
                    dingDanLX: "订单类型",
                    dingDanBeiZhu: "订单备注",
                },
                tkddDict:DICT_TYPE_ORDERS.THDD.key,
                exportTableData: [],
                condition: [],
                cardData: deepCopy(this.defaultCardData),
                bi: JYGL_SKMX_CARD_SUFFIX.BI.name,
                yuan: JYGL_SKMX_CARD_SUFFIX.YUAN.name,
                payDict: getDictType("T_PAY"),
                ordersDict: getDictType("T_ORDERS")
            }
        },
        created() {
            initDictByType('T_PAY')
            initDictByType('T_ORDERS')
        },
        methods: {
            refresh() { //默认刷新列表方法
                this.table.loading = true;
                if (!this.search.xiaoFeiSJBegin && !this.search.xiaoFeiSJEnd) {
                    let nowDate = new Date()
                    let firstDate = new Date(nowDate.getFullYear(), nowDate.getMonth(), 1)
                    this.search.xiaoFeiSJBegin = firstDate
                    this.search.xiaoFeiSJEnd = nowDate
                }
                this.getShouKuanTJ()
                return this.refreshService(Object.assign(this.search, this.searchNoClear)).then((response) => {
                    let ret = response.data;
                    this.table.data = ret.records;
                    if (this.table.data) {
                        this.table.data.forEach(item => {
                            if (!item.huiYuanShouJiHao) {
                                item.huiYuanShouJiHao = KONG_ZHI_XIAN_SHI.WSZ.name
                            }
                            if (!item.moLing) {
                                item.moLing = KONG_ZHI_XIAN_SHI.WML.name
                            }
                            if (!item.youHuiJuanId) {
                                item.youHuiJuanDK = KONG_ZHI_XIAN_SHI.WU.name
                            }
                        })
                        this.exportTableData = deepCopy(this.table.data)
                        this.exportTableData.forEach(item => {
                            let pay = this.payDict.find(element => element.key === item.zhiFuLX)
                            let orders = this.ordersDict.find(element => element.key === item.dingDanLX)
                            item.zhiFuLX = pay.value
                            item.dingDanLX = orders.value
                        })
                    }
                    this.table.total = ret.total || 0;
                    //处理11条数据删除一条不显示问题
                    if (this.searchNoClear.current !== 1 && (!ret.records || ret.records === 0)) {
                        this.searchNoClear.current--
                        this.refresh()
                        return
                    }
                    return response;
                }).catch(error => {
                    throw error;
                }).finally(() => this.table.loading = false)
            },
            handlePay(row) {
                this.$refs.zhiFuDetail.jinEDetailVisible = true
                let detailData = []
                detailData.push(row)
                this.$refs.zhiFuDetail.tableData = detailData
            },
            //获取卡片相关统计数据
            getShouKuanTJ() {
                this.service.getShouKuanMXTongJi(this.search).then((response) => {
                    this.cardData = deepMerge(this.defaultCardData, response.data)
                    if (this.cardData) {
                        this.cardData.xsbs = this.cardData.xsbs.concat(this.bi)
                        this.cardData.xsze = this.cardData.xsze.concat(this.yuan)
                        this.cardData.tkbs = this.cardData.tkbs.concat(this.bi)
                        this.cardData.tkze = this.cardData.tkze.concat(this.yuan)
                        this.cardData.ssze = this.cardData.ssze.concat(this.yuan)
                        this.cardData.hyzkje = this.cardData.hyzkje.concat(this.yuan)
                        this.cardData.yhjze = this.cardData.yhjze.concat(this.yuan)
                        this.cardData.ml = this.cardData.ml.concat(this.yuan)

                    }
                })
            },
            handleToDetail(row) {
                this.$refs.skmxDetail.detailVisible = true
                this.$refs.skmxDetail.shouKuanMX = row
            },
        },
    }
</script>

<style scoped>
    .page-tips {
        padding: 8px 16px;
        background-color: #ecf8ff;
        border-radius: 4px;
        border-left: 5px solid #409EFF;
        font-size: 13px;
        color: #5e6d82;
        line-height: 1.5em;
        margin-bottom: 10px;
    }

    .table-top-card-box {
        display: flex;
        margin-bottom: 10px;
        flex-wrap: wrap;
        /* justify-content: space-between; */
    }

    .shou-kuan-card {
        width: 11%;
    }
</style>
